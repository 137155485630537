.certificateCard {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  flex-wrap: wrap;
}

.certificateCard p {
  margin-bottom: 0;
  font-size: small;
}

.certImg {
  max-width: 100%;
  padding: 20px;
}

@media screen and (max-width: 555px) {
  .certificateCard {
    width: 100%;
  }

  .certImg {
    width: 100%;
  }
}

@media screen and (max-width: 755px) {
  .certificateCard {
    width: 50%;
  }

  .certImg {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .certImg {
    width: 70%;
  }
}