.serviceCard {
  padding: 20px;
  margin: 15px 0;
  max-width: 100%;
}

.serviceCard h3 {
  font-size: x-large;
  margin-top: 0;
  color: #384ea8;
}

.serviceCard p {
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .serviceCard {
    width: 30%;
  }
}