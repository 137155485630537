.home {
  background-color: #DEF0FF;
}

.bio {
  height: 700px;
  padding: 150px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #DEF0FF;
  color: #6A88FF;
}

.services {
  height: fit-content;
  text-align: center;
  background-color: white;
  color: #6A88FF;
  padding: 150px 50px;
}

.services a {
  text-decoration: none;
}

.bio h3, .services h3 {
  font-size: 1.5em;
}

.bio p, .services p {
  font-weight: 300;
}

.homeServices {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4em;
}

@media screen and (min-width: 768px) {
  .bio {
    padding: 25%;
  }

  .homeServices {
    flex-direction: row;
    margin: 0 20px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1280px) {
  .services {
    padding: 250px 300px;
  }

  .whatDesc {
    margin-bottom: 3em;
  }

  .bio {
    padding: 300px 500px;
  }

  .bio h3 {
    padding: 0 20px;
    margin-bottom: 1.2em;
  }
}