.approachCard {
  margin: 50px 10px;
  padding: 50px 30px;
  height: 250px;
  border: 1px solid rgba(33, 33, 97, 0.281);
  border-radius: 7px;
  display: flex;
  align-items: center;
}

.approachCard:hover, .approachCard:hover svg {
  background-color: #384ea8;
  color: white;
}

@media screen and (min-width: 470px) {
  .approachCard {
    width: 80%;
    margin: 20px;
  }
}

@media screen and (min-width: 767px) {
  .approachCard {
    width: 40%;
  }
}

@media screen and (min-width: 1268px) {
  .approachCard {
    width: 25%;
  }
}