.bio {
  padding: 50px;
  color: #6A88FF;
}

.bio h4 {
  font-size: 1.2em;
  margin: 2em 0 1em 0;
}

.bio p {
  font-weight: 300;
  color: #4360d4;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .bio {
    padding: 150px;
  }
}

@media screen and (min-width: 1280px) {
  .bio {
    padding: 50px 25%;
  }
}