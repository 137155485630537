.homeServiceCard {
  overflow: hidden;
  margin: 20px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  height: 200px;
  border-radius: 17px;
  background: #ffffff;
  box-shadow:  20px 20px 60px #d9d9d9,
              -20px -20px 60px #ffffff;
}

.cardTitle {
  position: relative;
  top: 0;
  margin: 0 0 30px 0;
  font-size: x-large;
  color: #6A88FF;
  z-index: 10;
}

.desc {
  margin-top: 0;
  font-size: small;
  z-index: 10;
}

@media screen and (min-width: 550px) {
  .homeServiceCard {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .homeServiceCard {
    width: 30%;
  }
}