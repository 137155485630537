.services {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 50px 100px 50px;
  background-color: #f0f8ff;
  color: #6A88FF;
}

.services h4 {
  color: #384ea8;
  text-align: center;
  padding: 0 10% 40px 10%;
  font-size: larger;
  margin: 0 auto;
  border-bottom: 1px solid rgb(39, 50, 114);
}

.approach {
  padding: 60px 50px 50px 50px;
}

.approachHeader {
  font-size: xx-large;
}

.subhead {
  font-size: x-large;
  margin-top: 0;
}

.approachIcon {
  font-size: 3em;
  display: block;
  color: #2b4096;
}

.approachDesc {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
}

.approachDesc p {
  margin-top: 0;
  font-weight: 300;
}

.approachPeak {
  margin-top: 2em;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 407px) {
  .approachIcon {
    display: none;
  }

  .approachDesc {
    margin: 0;
  }
}

@media screen and (min-width: 470px) {
  .approachCardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .services {
    padding: 50px 10%;
  }

  .serviceCards {
    display: flex;
    justify-content: center;
    margin: 50px 0 0 0;
  }
}