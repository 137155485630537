.certificationsContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  margin: 50px;
  margin-bottom: 0;
  padding: 25px;
}

.certificationsContainer h3, .codecademyCerts h3 {
  margin-bottom: 20px;
}

.msCert, .codecademyCerts {
  width: 50%;
  margin-bottom: 2em;
}

@media screen and (max-width: 420px) {
  .certificationsContainer {
    padding: 0;
  }
}

@media screen and (max-width: 555px) {
  .msCert, .codecademyCerts {
    width: 100%;
  }
}

@media screen and (max-width: 776px) {
  .certificationsContainer {
    flex-direction: column;
  }

  .msCert, .codecademyCerts {
    width: 100%;
  }
}