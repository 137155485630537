.project {
  padding: 0 20px 50px 20px;
  line-height: 150%;
}

.project h3 {
  margin-top: 3em;
}

.projectDesc {
  margin-top: 2em;
}

.site {
  padding: 10px 15px;
  text-decoration: none;
  background-color: #435CFF;
  color: white;
  border-radius: 36px;
}

.site svg {
  margin-left: 0.2em;
}

@media screen and (min-width: 768px) {
  .project {
    padding: 50px 100px;
  }
}

@media screen and (min-width: 1098px) {
  .project {
    padding: 50px 100px;
  }

  .deliverables, .projectDesc {
    padding: 0 250px;
  } 
}