.desktopNav {
  max-width: 100%;
  position: fixed;
  top: 25px;
  right: 10px;
  z-index: 9998;
}

.desktopNav ul {
  padding: 15px;
  border-radius: 36px;
  background-color: #ffffffc2;
  box-shadow: 5px 5px 15px rgba(145, 145, 145, 0.459);
}

.navButton {
  display: none;
  margin-top: 7px;
}

nav ul {
  padding: 0;
  list-style-type: none;
}

nav ul li {
  margin: 5px 25px;
  display: inline-block;
}

nav ul li a {
  font-size: x-small;
  padding: 10px;
  color: black;
  text-decoration: none;
}

.contact a {
  padding: 10px 15px;
  background-color: #435CFF;
  color: white;
  border-radius: 36px;
}

.contact a:hover {
  background-color: rgb(0, 23, 155);
  color: white;
}

@media screen and (max-width: 768px) {
  .desktopNav {
    display: none;
  }

  .navButton {
    display: flex;
    align-items: baseline;
    z-index: 9998;
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px;
    border: none;
    background-color: rgba(255, 255, 255, 0.555);
    font-weight: 700;
  }

  .navIcon {
    margin-left: 5px;
    align-self: center;
  }

  .mobileNav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: 10px solid rgb(229, 240, 255);
    background-color: rgb(255, 255, 255);
    z-index: 9997;
  }

  .mobileNav ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center
  }

  .mobileNav ul li {
    margin: 40px 0;
    font-weight: 600;
  }

  .mobileNav ul li a {
    font-size: large;
  }

  .mobileNavSocials {
    padding: 30px 60px;
  }
}