.projectTile {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: 250px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.781);
  border-radius: 17px;
  box-shadow:  20px 20px 60px #d9d9d9,
              -20px -20px 60px #ffffff;
}

.projectTile:hover {
  box-shadow:  15px 15px 30px #707070,
             -15px -15px 30px #ffffff;
}

.projectTile h3 {
  font-size: xx-large;
  margin: 0;
  margin-top: 20px;
  text-align: center;
}

.projectTile img {
  position: relative;
  top: -70px;
  left: -50px;
  width: 180%;
  z-index: -1;
}