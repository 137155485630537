.screenSwitch {
  position: fixed;
  top: -50px;
  left: -110%;
  height: 150%;
  width: 100vw;
  z-index: 9999;
  background: linear-gradient(to bottom, rgb(230, 234, 255), rgb(147, 181, 255), rgb(80, 135, 255), rgb(127, 165, 221));
  animation: switch 1.5s;
}

@keyframes switch {
  0% {
    left: 0;
  }
  60% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}