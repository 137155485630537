.benContainer {
  margin: 1.5em 0;
  max-width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.benImg {
  width: 150%;
  opacity: 0.8;
}

@media screen and (min-width: 768px) {
  .benImg {
    max-width: 80%;
  }
}

@media screen and (min-width: 1098px) {
  .benImg {
    max-width: 60%;
  }
}