.input {
  color: rgb(90, 90, 90);
}

.label {
  color: grey;
}

.inputContainer, .textArea {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .inputContainer, .textArea {
    width: 80%;
  }
}