.image {
  padding: 15px;
  max-width: 100%;
  background-color: white;
  margin: 20px 0;
}

@media screen and (max-width: 281px) {
  .image {
    max-width: 100%;
    padding: 5px;
    margin: 20px 0;
  }
}

@media screen and (min-width: 768px) {
  .image {
    margin: 20px;
    max-width: 40%;
  }
}