.techs {
  margin: 3em 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pill {
  font-size: 0.8em;
  margin: 10px 20px;
  padding: 5px 10px;
  background-color: rgb(173, 173, 173);
  color: white;
  border-radius: 36px;
  box-shadow: 5px 5px 10px rgb(229, 229, 229);
}