.blob {
  position: absolute;
  z-index: -1;
  margin-top: 1.5em;
}

@media screen and (max-width: 281px) {
  .blob {
    width: 70%;
    right: 10%;
    margin-top: 4.5em;
  }
}

@media screen and (min-width: 500px) {
  .blob {
    width: 70%;
    right: 15%;
  }
}

@media screen and (min-width: 768px) {
  .blob {
    width: 60%;
    right: 5%;
  }
}

@media screen and (min-width: 1000px) {
  .blob {
    width: 35%;
    right: 23%;
  }
}