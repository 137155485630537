header {
  height: 100vh;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(38, 38, 78);
  background-color: white;
  overflow: hidden;
}

.intro {
  margin: 15px;
  background-color: rgba(255, 255, 255, 0.644);
  z-index: 10;
  align-self: flex-start;
}

.intro h1 {
  font-size: calc(1.5rem + ((1vw - 7.68px) * 0.6944));
  font-weight: 400;
}

.intro h2 {
  margin-top: 0;
  font-size: calc(3rem + ((1vw - 7.68px) * 0.6944));
}

.ben {
  width: 100%;
  opacity: 0.9;
}

@media screen and (max-width: 450px) {
  header {
    flex-direction: column;
  }

  .intro {
    padding: 0 10px;
  }

  .ben {
    width: 150%;
  }
}

@media screen and (min-width: 768px) {
  header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .intro {
    margin-left: 20%;
    max-width: 70%;
    padding-right: 100px;
  }
}

@media screen and (min-width: 867px) {
  .ben {
    max-width: 70%;
  }
}

@media screen and (min-width: 1280px) {
  .ben {
    max-width: 50%;
  }
}