.languageContainer {
  width: 80%;
  margin-top: 2em;
}

.icon {
  font-size: 2.5em;
  margin: 15px;
}

@media screen and (min-width: 768px) {
  .languageContainer {
    width: 40%;
  }
}