.projects {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 50px 75px 50px;
}

.tech {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 50px;
  color: #6A88FF;
  background-color: #DEF0FF;
  text-align: center;
}

.tech h3 {
  font-size: 1.5em;
}

.tech p {
  font-weight: 300;
}

.projects a {
  width: 70%;
  margin: 15px;
  text-decoration: none;
  color: black;
}

@media screen and (min-width: 550px) {
  .projects a {
    width: 40%;
    padding: 10px;
  }
}

@media screen and (min-width: 768px) {
  .projects a {
    width: 24%;
  }
}

@media screen and (min-width: 1280px) {
  .projects {
    padding: 0 200px 100px 200px;
  }
}