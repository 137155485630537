.projectImages {
  margin: 4em 0;
  width: 100%;
  display: grid;
  justify-content: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.projectImage {
  max-width: 100%;
  justify-self: center;
  align-self: center;
}

.projectImage:nth-child(1), .projectImage:nth-child(4), .projectImage:nth-child(5) {
  grid-column: 1 / span 2;
}

.projectImage:nth-child(6) {
  grid-column-start: 1;
  grid-column-end: 1;
  background-color: rgb(175, 230, 255);
}

.projectImage:nth-child(7) {
  background-color: rgb(255, 226, 226);
}

.projectImage:nth-child(6), .projectImage:nth-child(7) {
  grid-column: 1 / span 2;
}

@media screen and (min-width: 768px) {
  .projectImages {
    max-width: 100%;
    grid-template-columns: 400px 400px;
  }

  .projectImage:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .projectImage:nth-child(7) {
    grid-column-start: 2;
    grid-column-end: 2;
  }
}