.benContainer {
  overflow: hidden;
  max-width: 100%;
  margin: 0;
  padding: 0 30px;
}

.benContainer h3 {
  margin-top: 0;
  position: relative;
  top: 50px;
  left: 10px;
  width: 90%;
  font-size: 2em;
  color: rgb(77, 77, 185);
}

.ben {
  width: 100%;
  margin-top: 1.5em;
  padding-left: 50px;
  border-radius: 50%;
}

@media screen and (max-width: 281px) {
  .ben {
    width: 120%;
    padding-left: 30px;
    margin-top: 4.5em;
  }
}

@media screen and (min-width: 500px) {
  .benContainer {
    max-width: 70%;
    justify-content: center;
    margin: auto;
  }
}

@media screen and (min-width: 768px) {
  .benContainer {
    margin: auto;
    display: flex;
    align-items: center;
    max-width: 80%;
  }

  .benContainer h3 {
    text-align: center;
    width: 40%;
    font-size: 3.5em;
  }

  .ben {
    width: 60%;
  }
}

@media screen and (min-width: 1000px) {
  .benContainer {
    max-width: 50%;
  }

  .benContainer h3 {
    font-size: 3em;
    text-align: start;
  }
}