.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 50px auto;
  padding: 0 15% 50px 15%;
}

.submitContactButton {
  margin: 30px 10px 10px 10px;
  position: relative;
}

.thankYou {
  display: none;
  padding: 25px;
  width: 60%;
  height: 40%;
  text-align: center;
  border-radius: 15px;
}

.thankYou svg {
  font-size: xx-large;
  color: rgb(0, 173, 0);
}

.messageError {
  display: none;
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 1.5em;
}

@media screen and (min-width: 768px) {
  .form {
    padding: 0 25%;
  }
}