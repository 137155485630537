.contactPara {
  padding: 20px 15%;
}

.contactPara {
  font-weight: 300;
}

label {
  font-weight: 500;
}