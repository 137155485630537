.photography {
  padding: 50px;
  background-color: #DEF0FF;
  color: #6A88FF;
}

.photography h3 {
  font-size: x-large;
}

.photography p {
  font-weight: 300;
  padding: 0;
}

.photos {
  display: flex;
  flex-direction: column;
}

.photoSocials {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  padding: 30px;
  font-size: 1.2em;
}

.photoSocialLink {
  color: black;
  text-decoration: none;
}

.openExtLink {
  padding-right: 5px;
}

@media screen and (min-width: 768px) {
  .photography {
    padding: 150px;
  }

  .photos {
    margin-top: 3em;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (min-width: 1280px) {
  .photography {
    padding: 50px 25%;
  }

  .photos {
    justify-content: space-between;
  }
}