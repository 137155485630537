footer {
  padding: 30px;
  height: 200px;
  color: rgb(37, 77, 112);
  background-color: rgb(238, 238, 238);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socials {
  display: flex;
  flex-direction: column;
}

.socials svg {
  margin: 10px 0;
}

.socialIcon {
  font-size: large;
  color: black;
}

footer a, footer p {
  font-weight: 300;
  font-size: small;
}

@media screen and (min-width: 768px) {
  footer {
    padding: 0 25%;
  }
}